import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Content from "../../../layout/Content/Content";
import PricingTable from "../PricingTable";
import MediaQuery from "../../../layout/MediaQuery";

const PricingFeaturesHeader = Styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2.7rem 0;
  box-shadow: 0px 2px 20px rgba(3, 3, 3, 0.1);
  border-radius: 2rem;
  margin-bottom: 1.6rem;
`;

const ColumnTitle = Styled.div`
  font-size: 2rem;
  width: calc(100%/4);
  text-align: center;
`;

type PricingFeaturesProps = {
  selectedPack: string;
  pricingTables: [
    { headline: string; features: [[string, string, string, string, string]] }
  ];
};

const PricingFeatures = ({
  pricingTables,
  selectedPack,
}: PricingFeaturesProps) => (
  <Content narrow>
    <MediaQuery greaterThan="1024px">
      <PricingFeaturesHeader>
        <ColumnTitle>Compare Features</ColumnTitle>
        <ColumnTitle>Free</ColumnTitle>
        <ColumnTitle>Pro</ColumnTitle>
        <ColumnTitle>Business</ColumnTitle>
        <ColumnTitle>Enterprise</ColumnTitle>
      </PricingFeaturesHeader>
    </MediaQuery>

    {pricingTables.map((pricingTable) => (
      <PricingTable
        selectedPack={selectedPack}
        key={pricingTable.headline}
        pricingTable={pricingTable}
      />
    ))}
  </Content>
);

export default PricingFeatures;
