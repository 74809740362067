import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import { useCallback } from "react";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";
import Content from "../../../layout/Content/Content";
import PricingCard, {
  ButtonExtended,
  PricingCardContainer,
} from "../../../cards/PricingCard";
import SegmentedControl from "../../../buttons/SegmentedControl";
import MediaQuery from "../../../layout/MediaQuery";
import PricingEnterpriseMobileHeader from "../../pricing/PricingEnterpriseMobileHeader";
import PricingEnterpriseHeader from "../../pricing/PricingEnterpriseHeader";

const Container = Styled.div`
  width: 100%;
  margin: 16rem 0 4rem 0;
  text-align: center;
  
  ${Media.lessThan("1024px")`
    margin: 11rem 0 4.5rem 0;
  `}
  
  ${Media.lessThan("small")`
    margin: 9rem 0 4.5rem 0;
  `}
`;

const ExtendedHeadline = Styled(Headline)`
    font-size: 3.6rem;
    line-height: 4.3rem;
  
  ${Media.greaterThan("large")`
    font-size: 5.6rem;
    line-height: 6.5rem;
  `}
 
`;

const ExtendedDescription = Styled(Description)`
    margin: 0 0 10rem 0;
    
   ${Media.lessThan("1024px")`
    margin: 0 0 3rem 0;
  `}
  
   ${Media.lessThan("small")`
    max-width: 26rem;
    margin 0 auto 3rem auto;
  `}
`;

const PackSegmentedControl = Styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
`;

const Columns = Styled.div`
    display: flex;
    justify-content: space-between;
    
    ${PricingCardContainer} {
        width: 31.5%;
        border-radius: 2rem;
        padding: 2rem 5rem 0 5rem;
        display: flex;
        flex-direction: column;
        
        ${Media.lessThan("1024px")`
            width: 100%;
            border-radius: 2rem;
            padding: 5rem;
        `}
    }
`;

type PricingHeaderProps = {
  headline: string;
  description: string;
  cardOne: any;
  cardTwo: any;
  cardThree: any;
  selectedPack: string;
  onSelectedPackChanged: Function;
  items: Array<string>;
};

const PricingHeader = ({
  headline,
  description,
  cardOne,
  cardTwo,
  cardThree,
  selectedPack,
  onSelectedPackChanged,
  items,
}: PricingHeaderProps) => {
  const handleSelectedPackChanged = useCallback(
    (item) => {
      onSelectedPackChanged(item);
    },
    [onSelectedPackChanged]
  );

  const cardOnePricingCard = (
    <PricingCard
      description={cardOne.description}
      items={cardOne.items}
      price={cardOne.price}
      priceDetail={cardOne.priceDetail}
      title={cardOne.title}
      customFeatures={false}
      customBilling={false}
    />
  );

  const cardTwoPricingCard = (
    <PricingCard
      description={cardTwo.description}
      items={cardTwo.items}
      price={cardTwo.price}
      priceDetail={cardTwo.priceDetail}
      title={cardTwo.title}
      billing={cardTwo.billing}
      type={cardTwo.type}
      customFeatures={false}
      customBilling={true}
    />
  );

  const cardThreePricingCard = (
    <PricingCard
      description={cardThree.description}
      items={cardThree.items}
      price={cardThree.price}
      priceDetail={cardThree.priceDetail}
      billing={cardThree.billing}
      title={cardThree.title}
      customFeatures={false}
      customBilling={false}
    />
  );

  return (
    <Content narrow>
      <Container>
        <ExtendedHeadline as="h1">{headline}</ExtendedHeadline>
        <ExtendedDescription>{description}</ExtendedDescription>

        <MediaQuery lessThan="1024px">
          <PricingEnterpriseMobileHeader />
          <ExtendedDescription>
            Self-serve plans for everyone, cancel anytime.
          </ExtendedDescription>
        </MediaQuery>

        <MediaQuery lessThan="1024px">
          <PackSegmentedControl>
            <SegmentedControl
              items={items}
              selectedSegment={selectedPack}
              onSelectedSegmentChanged={handleSelectedPackChanged}
            />
          </PackSegmentedControl>
        </MediaQuery>

        <MediaQuery greaterThan="1024px">
          <Columns>
            {cardOnePricingCard}
            {cardTwoPricingCard}
            {cardThreePricingCard}
          </Columns>
        </MediaQuery>

        <MediaQuery lessThan="1024px">
          <Columns>
            {selectedPack === cardOne.title && cardOnePricingCard}
            {selectedPack === cardTwo.title && cardTwoPricingCard}
            {selectedPack === cardThree.title && cardThreePricingCard}
          </Columns>
        </MediaQuery>
      </Container>
    </Content>
  );
};

export default PricingHeader;
