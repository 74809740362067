import * as React from "react";
import Styled from "styled-components/macro";
import Image, { GatsbyImageProps } from "gatsby-image";
import Media from "@uizard-io/styled-media-query";
import { Link } from "gatsby-plugin-react-i18next";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";
import Button from "../../../buttons/Button";
import Content from "../../../layout/Content/Content";
import { HybridLink } from "../../../layout/Footer";
import CheckIcon from "../../../../assets/check.svg";
import TypeFormButton from 'components/buttons/TypeFormButton';


const TypeFormButtonContainer = Styled(TypeFormButton)`
  background-color: transparent;
`;

const Container = Styled.div`
  margin-bottom: 8rem;
`;

const ExtendedContent = Styled(Content)`
  background: rgb(57,161,254);
  background: linear-gradient(90deg,rgba(57,161,254,0.75) 0%,rgba(107,82,252,0.75) 100%);
  color: ${(props) => props.theme.colors.black};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 2rem;
  padding: 1.5rem 5rem 1.5rem 5rem;
`;

const ExtendedButton = Styled(Button)`
  height: 4.5rem;
  width: 26rem;
  color: white;
  font-size: 1.6rem;
  border-radius: 1rem;
`;

const ExtendedHeadline = Styled(Headline)`
    margin-bottom: 0.5rem;
    font-size: 3rem;
    line-height: 3rem;
`;

const ExtendedDescription = Styled(Description)`
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0;
`;

const Column = Styled.div`
`;

const ListItems = Styled.ul`
    padding: 0;
`;

const ListItemIcon = Styled.span`
`;

const ListItemText = Styled.p`
    margin: 0 0 0 1rem;
    font-weight: ${(props) => (props.bold ? "500" : "400")}
`;

const ListItem = Styled.li`
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    list-style-type: none;
`;

const PricingEnterpriseHeader = () => (
  <Container>
    <ExtendedContent narrow>
      <Column>
        <ExtendedHeadline>Enterprise</ExtendedHeadline>

        <ExtendedDescription>
          For organizations with specific needs
        </ExtendedDescription>
      </Column>

      <Column>
        <ListItems>
          <ListItem>
            <ListItemIcon>
              <CheckIcon fill="black" />
            </ListItemIcon>
            <ListItemText bold={true}>
              Flat price for unlimited creators and viewers
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckIcon fill="black" />
            </ListItemIcon>
            <ListItemText>
              Design system setup, unlimited teams, and more
            </ListItemText>
          </ListItem>
        </ListItems>
      </Column>

      <Column>
        <TypeFormButtonContainer id="WccWfRLY">
          <ExtendedButton type="submit" solid variant="black">
            Contact Sales
          </ExtendedButton>
        </TypeFormButtonContainer>
      </Column>
    </ExtendedContent>
  </Container>
);

export default PricingEnterpriseHeader;
