import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Headline from "../../../texts/Headline";
// @ts-ignore
import CheckIcon from "../../../../assets/check.svg";
import Button from "../../../buttons/Button";
import TypeFormButton from '../../../buttons/TypeFormButton';

const TypeFormButtonContainer = Styled(TypeFormButton)`
  background-color: transparent;
`;

const Container = Styled.div`
  width: 100%;
  margin-bottom: 4rem;
`;

const ExtendedHeadline = Styled(Headline)`
    font-size: 2.6rem;
    margin-bottom: 1rem;
`;

const Table = Styled.table`
    font-size: 1.8rem;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse; 
    margin-bottom: ${(props) =>
      props.headline === "Security and Support" ? "0" : "3rem"};
`;

const Td = Styled.td`
    text-align: center;
    margin: 0;
    padding: 0.8rem 0;
    
    ${(props) =>
      props.mobile &&
      `
        display: none;
    `}
    
    ${Media.lessThan("1024px")`
        ${(props) =>
          props.mobile &&
          `
            display: block;
        `}
        
        ${(props) =>
          props.desktop &&
          !props.full &&
          `
            display: none;
        `}
    `}
    
    ${Media.lessThan("500px")`   
        ${(props) =>
          props.desktop &&
          props.full &&
          `
            display: none;
        `}
    `}
   
`;

const SmallTd = Styled(Td)`
  font-size: 1.6rem;
`;

const Tbody = Styled.tbody``;

const Tr = Styled.tr`
    border-bottom: 1px solid #eeeeee;
    
    ${Td}:first-of-type {
      text-align: left;
    }
    
    ${Media.lessThan("1024px")`
        font-size: 1.4rem;
        
        ${Td}:first-of-type {
            font-weight: 500;
        }
    
        ${Td}:last-of-type {
            text-align: right;
        }
    `}
`;

const ExtendedButton = Styled(Button)`
    height: 45px;
    font-size: 16px;
    width: 100%;
    
    ${Media.greaterThan("500px")`
        margin-top: 0;
    `}
`;

const NavButtonLink = Styled.a`
  
`;

type PricingTableProps = {
  selectedPack: string;
  pricingTable: {
    headline: string;
    features: [[string, string, string, string, string]];
  };
};

const PricingTable = ({ pricingTable, selectedPack }: PricingTableProps) => (
  <Container>
    <ExtendedHeadline>{pricingTable.headline}</ExtendedHeadline>
    <Table headline={pricingTable.headline}>
      <Tbody>
        {pricingTable.features.map((feature) => {
          const packOneFeatureDesktop = (
            <SmallTd desktop>
              {feature[1] === "check" ? (
                <CheckIcon fill="#000000" />
              ) : (
                [feature[1]]
              )}
            </SmallTd>
          );
          const packTwoFeatureDesktop = (
            <SmallTd desktop>
              {feature[2] === "check" ? (
                <CheckIcon fill="#000000" />
              ) : (
                [feature[2]]
              )}
            </SmallTd>
          );
          const packThreeFeatureDesktop = (
            <SmallTd desktop>
              {feature[3] === "check" ? (
                <CheckIcon fill="#000000" />
              ) : (
                [feature[3]]
              )}
            </SmallTd>
          );
          const packFourFeatureDesktop = (
            <SmallTd desktop>
              {feature[4] === "check" ? (
                <CheckIcon fill="#000000" />
              ) : feature[4].indexOf("[sales") === 0 ? (
                <TypeFormButtonContainer id="WccWfRLY">
                  <ExtendedButton solid variant="primarylight">
                    Contact Sales
                  </ExtendedButton>
                </TypeFormButtonContainer>
              ) : (
                [feature[4]]
              )}
            </SmallTd>
          );

          const packOneFeatureMobile = (
            <SmallTd mobile>
              {feature[1] === "check" ? (
                <CheckIcon fill="#000000" />
              ) : (
                [feature[1]]
              )}
            </SmallTd>
          );
          const packTwoFeatureMobile = (
            <SmallTd mobile>
              {feature[2] === "check" ? (
                <CheckIcon fill="#000000" />
              ) : (
                [feature[2]]
              )}
            </SmallTd>
          );
          const packThreeFeatureMobile = (
            <SmallTd mobile>
              {feature[3] === "check" ? (
                <CheckIcon fill="#000000" />
              ) : (
                [feature[3]]
              )}
            </SmallTd>
          );
          const packFourFeatureMobile = (
            <SmallTd mobile>
              {feature[4] === "check" ? (
                <CheckIcon fill="#000000" />
              ) : feature[4].indexOf("[sales") === 0 ? (
                <TypeFormButtonContainer id="WccWfRLY">
                  <ExtendedButton solid variant="primarylight">
                    Contact Sales
                  </ExtendedButton>
                </TypeFormButtonContainer>
              ) : (
                [feature[4]]
              )}
            </SmallTd>
          );

          return (
            <Tr key={feature}>
              <Td>{feature[0]}</Td>
              {feature[1] && packOneFeatureDesktop}
              {feature[2] && packTwoFeatureDesktop}
              {feature[3] && packThreeFeatureDesktop}
              {feature[4] && packFourFeatureDesktop}

              {feature[1] && selectedPack === "Free" && packOneFeatureMobile}
              {feature[2] && selectedPack === "Pro" && packTwoFeatureMobile}
              {feature[3] &&
                selectedPack === "Business" &&
                packThreeFeatureMobile}
              {feature[4] &&
                selectedPack === "Enterprise" &&
                packFourFeatureMobile}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  </Container>
);

export default PricingTable;
