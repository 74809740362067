import * as React from "react";
import { graphql } from "gatsby";
import { useState } from "react";
import Meta from "../../components/meta";
import Layout from "../../components/layout/Layout";
import PricingHeader from "../../components/pages/pricing/PricingHeader";
import PricingFeatures from "../../components/pages/pricing/PricingFeatures";
import PricingEnterprise from "../../components/pages/pricing/PricingEnterprise";
import PricingFooter from "../../components/pages/pricing/PricingFooter";
import PricingEnterpriseHeader from "../../components/pages/pricing/PricingEnterpriseHeader";
import MediaQuery from "../../components/layout/MediaQuery";

const Pricing = ({ data }) => {
  const items = ["Free", "Pro", "Business"];
  const [selectedPack, setSelectedPack] = useState(items[1]);

  return (
    <Layout>
      <Meta
        title="Uizard Pricing | Explore Our Pricing Plans | Uizard"
        description="Bring your ideas to life with Uizard. Explore our pricing tiers and find what works best for you and your team. Bring your designs to life today."
        url="/pricing/"
      />

      <PricingHeader
        selectedPack={selectedPack}
        onSelectedPackChanged={setSelectedPack}
        items={items}
        headline="Uizard Pricing"
        description="Bring your ideas to life in minutes with Uizard"
        cardOne={{
          title: "Free",
          price: "$0",
          priceDetail: "Creator/month",
          description: "For students and hobbyists",
          items: ["Unlimited free viewers", "2 projects", "10 free templates"],
        }}
        cardTwo={{
          title: "Pro",
          price: "$12",
          priceDetail: "Creator/month",
          description: "For startups and growing businesses",
          type: "dark",
          items: [
            "bold-Everything from Free",
            "Unlimited screens",
            "Access to all templates",
          ],
        }}
        cardThree={{
          title: "Business",
          price: "$49",
          priceDetail: "Creator/month",
          description: "For large organizations",
          billing: "Billed yearly",
          items: [
            "bold-Everything from Pro",
            "Everything unlimited",
            "Priority support",
          ],
        }}
      />

      <MediaQuery greaterThan="1024px">
        <PricingEnterpriseHeader />
      </MediaQuery>

      <PricingFeatures
        selectedPack={selectedPack}
        pricingTables={[
          {
            headline: "Easy Design and Prototyping",
            // @ts-ignore
            features: [
              [
                "Creators per organization",
                "unlimited",
                "unlimited",
                "unlimited",
                "unlimited",
              ],
              [
                "Free viewers",
                "unlimited",
                "unlimited",
                "unlimited",
                "unlimited",
              ],
              ["Projects", "up to 2", "up to 100", "unlimited", "unlimited"],
              [
                "Screens per projects",
                "up to 5",
                "unlimited",
                "unlimited",
                "unlimited",
              ],
              ["Unlimited file storage", "check", "check", "check", "check"],
              [
                "Pre-made templates",
                "10",
                "full library access",
                "full library access",
                "full library access",
              ],
              [
                "Save custom templates",
                "up to 5",
                "up to 100",
                "unlimited",
                "unlimited",
              ],
              ["Design interactions", "check", "check", "check", "check"],
              ["Transition animations", "check", "check", "check", "check"],
              [
                "Disable prototype hotspots",
                "check",
                "check",
                "check",
                "check",
              ],
              ["Wireframe mode switcher", "check", "check", "check", "check"],
              ["Team asset libraries", "check", "check", "check", "check"],
              ["Export to svg, jpg, png, pdf", "check", "check", "check", "check"],
              ["Developer handoff in CSS and React components", " ", "check", "check", "check"],
              [
                "Custom fonts and custom components",
                " ",
                " ",
                " ",
                "[sales:/enterprise/#contact_sales]",
              ],
              [
                "Custom template setup",
                " ",
                " ",
                " ",
                "[sales:/enterprise/#contact_sales]",
              ],
            ],
          },
          {
            headline: "AI Design Assistant",
            // @ts-ignore
            features: [
              [
                "Generate projects from text (Autodesigner)",
                "2 generations/month",
                "500 generations/month",
                "unlimited",
                "unlimited",
              ],
              [
                "Generate screen designs from text (Autodesigner)",
                "5 generations/month",
                "500 generations/month",
                "unlimited",
                "unlimited",
              ],
              [
                "Generate screens from screenshots",
                "10 scans/month",
                "500 scans/month",
                "unlimited",
                "unlimited",
              ],
              [
                "Generate screens from scribbles and wireframes",
                "10 scans/month",
                "500 scans/month",
                "unlimited",
                "unlimited",
              ],
              [
                "Generate heatmaps",
                "5 generations/month",
                "500 generations/month",
                "unlimited",
                "unlimited",
              ],
              [
                "Generate themes and UI kits from text",
                "5 generations/month",
                "500 generations/month",
                "unlimited",
                "unlimited",
              ],
              [
                "Generate themes and UI kits from screenshots",
                "5 generations/month",
                "500 generations/month",
                "unlimited",
                "unlimited",
              ],
              [
                "Generate themes and UI kits from URLs",
                "5 generations/month",
                "500 generations/month",
                "unlimited",
                "unlimited",
              ],
              [
                "Generate images from text",
                "5 generations/month",
                "500 generations/month",
                "unlimited",
                "unlimited",
              ],
              [
                "Text assistant",
                "5 suggestions/month",
                "500 suggestions/month",
                "unlimited",
                "unlimited",
              ],
              [
                "Image assistant",
                "5 suggestions/month",
                "500 suggestions/month",
                "unlimited",
                "unlimited",
              ],
            ],
          },
          {
            headline: "Collaboration and Admin",
            features: [
              [
                "Multiplayer real-time collaboration",
                "check",
                "check",
                "check",
                "check",
              ],
              ["Commenting", "check", "check", "check", "check"],
              ["Public sharable links", "check", "check", "check", "check"],
              ["Quick project sharing", "check", "check", "check", "check"],
              [
                "Project embedding on the web",
                "check",
                "check",
                "check",
                "check",
              ],
              ["Private projects", " ", "check", "check", "check"],
              [
                "Centralized team administration",
                " ",
                " ",
                "check",
                "check",
              ],
              ["Custom workspaces", " ", " ", " ", "check"],
              ["Unlimited teams", " ", " ", " ", "check"],
            ],
          },
          {
            headline: "Security and Support",
            features: [
              [
                "Customer support",
                "self-serve",
                "email and live chat",
                "priority support",
                "premium support",
              ],
              ["Onboarding and training", " ", " ", "check", "check"],
              ["Dedicated account manager", " ", " ", "check", "check"],
              ["Monthly check-in calls", " ", " ", "check", "check"],
              ["Template and design system setup", " ", " ", " ", "check"],
              ["Team setup", " ", " ", " ", "check"],
              [
                "Single Sign-On (SSO) integration",
                " ",
                " ",
                " ",
                "[sales:/enterprise/#contact_sales]",
              ],
              [
                "Two factor authentication (2FA)",
                " ",
                " ",
                " ",
                "[sales:/enterprise/#contact_sales]",
              ],
            ],
          },
        ]}
      />

      {/*
      <PricingFooter
        items={items}
        selectedPack={selectedPack}
        onSelectedPackChanged={setSelectedPack}
        cardOne={{
          title: 'Free',
        }}
        cardTwo={{
          title: 'Pro',
          type: 'dark',
        }}
        cardThree={{
          title: 'Enterprise',
        }}
      />
      */}

      <PricingEnterprise
        enterpriseLeftImageA={data.enterpriseLeftImageA.childImageSharp}
        enterpriseRightImageA={data.enterpriseRightImageA.childImageSharp}
      />
    </Layout>
  );
};

export default Pricing;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    enterpriseLeftImageA: file(
      relativePath: { eq: "pricing/enterprise-left-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 630, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    enterpriseRightImageA: file(
      relativePath: { eq: "pricing/enterprise-right-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 630, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
