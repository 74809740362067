import * as React from "react";
import Styled from "styled-components/macro";
import Image, { GatsbyImageProps } from "gatsby-image";
import Media from "@uizard-io/styled-media-query";
import { Link } from "gatsby-plugin-react-i18next";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";
import Button from "../../../buttons/Button";
import Content from "../../../layout/Content/Content";
import { HybridLink } from "../../../layout/Footer";

const ExtendedLink = Styled(HybridLink)`
`;

const Container = Styled.div`
`;

const ExtendedContent = Styled(Content)`
  background-color: ${(props) => props.theme.colors.black}; 
  color: white;
  display: flex;
  justify-content: space-between;
  padding-top: 5rem;
  border-radius: 2rem;
  overflow: hidden;
    position: relative;
  
  ${Media.lessThan("medium")`
     padding-top: 4rem;
  `}
  
  ${Media.lessThan("small")`
     padding-top: 3.5rem;
  `}
`;

const MiddleContainer = Styled.div`
  max-width: 26rem;
  text-align: center;
  padding-bottom: 5rem;
  margin: 0 9rem;
  
  ${Media.lessThan("large")`
    margin:  0 7rem;
  `}
  
  ${Media.lessThan("medium")`
    padding: 0;
    padding-bottom: 4rem;
  `}
  
  ${Media.lessThan("600px")`
    margin:  0 3rem;
  `}
  
  ${Media.lessThan("small")`
    margin: 0 auto;
    padding-bottom: 3.5rem;
  `}
`;

const LeftContainer = Styled.div`
  bottom: 0;
  width: 100%;
  
  ${Media.lessThan("small")`
    display: none;
  `}
`;

const RightContainer = Styled.div`
  width: 100%;
  
  ${Media.lessThan("small")`
    display: none;
  `}
`;

const ExtendedButton = Styled(Button)`
  height: 4.5rem;
  margin-top: 1.5rem;
  width: 26rem;
  color: white;
  font-size: 1.6rem;
  border-radius: 1rem;
  
  ${Media.lessThan("small")`
    margin-top: 1.5rem;
  `}
`;

const ExtendedHeadline = Styled(Headline)`
    margin-bottom: 1rem;
    font-size: 3rem;
    line-height: 3.6rem;
`;

const ExtendedDescription = Styled(Description)`
    font-size: 1.6rem;
    margin-bottom: 0;
    padding: 0 3.6rem;
`;

const ImageLeft = Styled(Image)`
    width: 100%;
    height: 100%;
`;

const ImageRight = Styled(Image)`
    width: 100%;
    height: 100%;
`;

const OverlayLeft = Styled.div`
    width: 9rem;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 90%;
    z-index: 5;
    background: linear-gradient(to right, black, rgba(0,0,0,.0));
    
    ${Media.lessThan("700px")`
      display: none;
    `}
`;

const OverlayRight = Styled.div`
    background: black;
    width: 9rem;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 90%;
    z-index: 5;
    background: linear-gradient(to left, black, rgba(0,0,0,.0));
    
    ${Media.lessThan("700px")`
      display: none;
    `}
`;

const OverlayBottomLeft = Styled.div`
    background: black;
    height: 9rem;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 31%;
    z-index: 5;
    background: linear-gradient(to top, black, rgba(0,0,0,.0));
    
    ${Media.lessThan("medium")`
      width: 14.7rem;
    `}
    
    ${Media.lessThan("700px")`
      display: none;
    `}
`;

const OverlayBottomRight = Styled.div`
    background: black;
    height: 9rem;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 31%;
    z-index: 5;
    background: linear-gradient(to top, black, rgba(0,0,0,.0));
    ${Media.lessThan("medium")`
      width: 14.7rem;
    `}
    
    ${Media.lessThan("700px")`
      display: none;
    `}
`;

type PricingEnterpriseProps = {
  enterpriseLeftImageA: GatsbyImageProps;
  enterpriseRightImageA: GatsbyImageProps;
};

const PricingEnterprise = ({
  enterpriseLeftImageA,
  enterpriseRightImageA,
}: PricingEnterpriseProps) => (
  <Container>
    <ExtendedContent narrow>
      <OverlayLeft />
      <OverlayRight />
      <OverlayBottomLeft />
      <OverlayBottomRight />
      <LeftContainer>
        <ImageLeft
          fluid={
            "fluid" in enterpriseLeftImageA ? enterpriseLeftImageA.fluid : ""
          }
          alt="Screenshot of the team management section in the Uizard software"
        />
      </LeftContainer>
      <MiddleContainer>
        <ExtendedHeadline>Enterprise</ExtendedHeadline>

        <ExtendedDescription>
          All the greatness of Pro and Business, plus features tailored to your
          organization
        </ExtendedDescription>

        <ExtendedLink to="/enterprise/">
          <ExtendedButton type="submit" solid variant="primarylight">
            More about Uizard Enterprise
          </ExtendedButton>
        </ExtendedLink>
      </MiddleContainer>
      <RightContainer>
        <ImageRight
          fluid={
            "fluid" in enterpriseRightImageA ? enterpriseRightImageA.fluid : ""
          }
          alt="Screenshot of the project overview section in the Uizard software"
        />
      </RightContainer>
    </ExtendedContent>
  </Container>
);

export default PricingEnterprise;
