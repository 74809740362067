import * as React from "react";
import Styled from "styled-components/macro";
import { useState, useCallback } from "react";
import Media from "@uizard-io/styled-media-query";

const Container = Styled.div`
  border-radius: 1rem;
  background-color: ${(props) => props.theme.colors.gray.lighter}; 
  padding: 0.5rem;
`;

const Item = Styled.button`
  border-radius: 1rem;
  min-width: 10rem;
  padding: 0.75rem 0;
  border: none;
  font-weight: 500;
  background-color: ${(props) =>
    props.selected === "true" ? "#e7e7e7" : props.theme.colors.gray.lighter};
  transition: all 0.2s ease;
  
  ${Media.lessThan("small")`
    min-width: 9rem;
  `}
`;

type PricingFeaturesProps = {
  items: Array<string>;
  selectedSegment: string;
  onSelectedSegmentChanged: Function;
};

const SegmentedControl = ({
  items,
  selectedSegment,
  onSelectedSegmentChanged,
}: PricingFeaturesProps) => {
  const handleSelectedControlChanged = useCallback(
    (item) => {
      onSelectedSegmentChanged(item);
    },
    [onSelectedSegmentChanged]
  );

  return (
    <Container>
      {items.map((item) => (
        <Item
          selected={selectedSegment === item ? "true" : "false"}
          onClick={() => {
            handleSelectedControlChanged(item);
          }}
        >
          {item}
        </Item>
      ))}
    </Container>
  );
};

export default SegmentedControl;
