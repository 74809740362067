import * as React from "react";
import constants from "constants/index";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import { Link } from "gatsby-plugin-react-i18next";
import Description from "../../texts/Description";
// @ts-ignore
import CheckIcon from "../../../assets/check.svg";
import Button from "../../buttons/Button";

const HybridLink = ({ children, ...props }) => {
  if (props.to.includes("http")) {
    // eslint-disable-next-line react/jsx-filename-extension
    return (
      <a href={props.to} {...props}>
        {children}
      </a>
    );
  }

  // @ts-ignore
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Link {...props}>{children}</Link>;
};

export const PricingCardContainer = Styled.div`
    text-align: left;
    position: relative;
    background-color: ${(props) =>
      props.type === "dark"
        ? props.theme.colors.black
        : props.theme.colors.gray.lighter};
    color: ${(props) =>
      props.type === "dark"
        ? props.theme.colors.white
        : props.theme.colors.black}
`;

const Title = Styled.p`
    font-weight: 600;
    font-size: 1.8rem;
    color: ${(props) =>
      props.type === "dark"
        ? props.theme.colors.white
        : props.theme.colors.gray.darker};
    margin-bottom: 1rem;
`;

const PriceColumns = Styled.div`
    display: flex;
    margin-bottom: 1.5rem;
`;

const Price = Styled.p`
    font-weight: 600;
    font-size: 7.2rem;
    margin: 0 1rem 0 0;
    line-height: 6rem;
    position: relative;
`;

const PriceDetail = Styled.p`
    font-size: 1.6rem;
    font-weight: 400;
    align-self: flex-end;
    transform: translateY(1.2rem);
`;

const getTextColor = ({ type }) => {
  if (type === "dark") {
    return "white";
  }
  return "black";
};

const getBackgroundColor = ({ type }) => {
  if (type === "dark") {
    return "#262626";
  }
  return "#F2F2F2";
};

const Billing = Styled.p`
    margin: 0;
    font-weight: 600;
    position: absolute;
    top: 3.8rem;
    right: 5rem;
    font-size: 1.4rem;
    padding: 0.3rem 0.8rem;
    border-radius: 0.5rem;
    background-color: ${getBackgroundColor};
    color: ${getTextColor};
`;

const ExtendedDescription = Styled(Description)`
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
`;

const ListItems = Styled.ul`
    padding: 0;
`;

const ListItemIcon = Styled.span`
`;

const ListItemText = Styled.p`
    margin: 0 0 0 1rem;
    font-weight: ${(props) => (props.bold ? "500" : "400")}
`;

const ListItem = Styled.li`
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    list-style-type: none;
`;

const CustomRequest = Styled.p``;

const ExtendedLink = Styled(HybridLink)`
    color: #219BFF;
    font-weight: 600;
`;

export const ButtonExtended = Styled(Button)`
  text-align: center;
  width: 100%;
  height: 4.7rem;
  font-size: 1.6rem;
  line-height: 1.6rem;
  margin-top: 1.5rem;
  border-radius: 1rem;

  ${Media.lessThan("large")`
    font-size: 1.6rem;
    line-height: 1.6rem;
  `}
`;

const NavButtonLink = Styled.a`
  
`;

type PricingCardProps = {
  title: string;
  price: string;
  priceDetail: string;
  description: string;
  billing?: string;
  items: [string];
  type?: string;
  customFeatures: Boolean;
  customBilling: Boolean;
};

const PricingCard = ({
  title,
  price,
  priceDetail,
  description,
  billing,
  items,
  type,
  customFeatures,
  customBilling,
}: PricingCardProps) => (
  <PricingCardContainer type={type}>
    <Title type={type}>{title}</Title>

    {billing && <Billing type={type}>{billing}</Billing>}

    <PriceColumns>
      <Price>{price}</Price>
      <PriceDetail>{priceDetail}</PriceDetail>
    </PriceColumns>

    <ExtendedDescription>{description}</ExtendedDescription>

    <ListItems>
      {items.map((item) => (
        <ListItem key={item}>
          <ListItemIcon>
            <CheckIcon fill={type === "dark" ? "white" : "black"} />
          </ListItemIcon>
          <ListItemText bold={item.substring(0, 5) === "bold-"}>
            {item.substring(0, 5) === "bold-"
              ? item.substring(5, item.length)
              : item}
          </ListItemText>
        </ListItem>
      ))}
    </ListItems>

    <NavButtonLink
      href={`https://${
        constants.APP_DOMAIN
      }/auth/sign-up/plan/${title.toLowerCase()}`}
    >
      <ButtonExtended
        type="submit"
        variant={type !== "dark" ? "primarylight" : "white"}
        loaderVariant="white"
        solid={1}
      >
        Get started with {title}
      </ButtonExtended>
    </NavButtonLink>

    {customFeatures && (
      <CustomRequest>
        Have specific needs for your organization?
        <ExtendedLink to="/enterprise/#contact_sales">
          &nbsp;Contact Sales
        </ExtendedLink>
      </CustomRequest>
    )}

    {customBilling && (
      <CustomRequest>
        $12 per month if billed yearly ($144) or $19 per month if billed monthly
      </CustomRequest>
    )}
  </PricingCardContainer>
);

export default PricingCard;

PricingCard.defaultProps = {
  billing: null,
  type: "light",
};
